<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <div class="separate">
          <el-drawer title="选择部门" :visible.sync="drawer_show" direction="ltr">
            <BepartmentTree @on_controlid="on_controlid" />
          </el-drawer>
          <div class="tablemessage">
            <p class="describe">筛选</p>
            <div class="segmentation"></div>
            <div class="from_submit">
              <el-form :model="formLabelAlign" class="from">
                <el-form-item class="BM" style="width: 310px;">
                  <div class="justify">部 门：<span></span></div>
                  <el-input disabled @click.native="drawer_show = true" placeholder="请点击选择部门" size="mini" class="form-inp"
                    v-model="department_name"></el-input>
                </el-form-item>
                <el-form-item label="名称：" label-width="60px" style="width: 310px;">
                  <el-input placeholder="名称" size="mini" class="form-inp" v-model="formLabelAlign.name"></el-input>
                </el-form-item>
                <el-form-item class="DJ" style="width: 400px;">
                  <div class="justify">等 级：<span></span></div>
                  <el-select v-model="formLabelAlign.level_color" placeholder="请选择等级">
                    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="巡检周期：" label-width="90px" style="width: 310px;">
                  <el-select v-model="formLabelAlign.check_times" placeholder="请选择巡检周期">
                    <el-option v-for="item in check_times" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>

                <!-- <el-form-item label="评估状态：">
                  <el-select
                    v-model="formLabelAlign.status"
                    placeholder="请选择评估状态"
                  >
                    <el-option
                      v-for="item in status_list"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item> -->

                <el-form-item label="分类：" label-width="60px" style="width: 310px;">
                  <el-cascader style="width: 200px" v-model="formLabelAlign.cate_id" :options="catesList" :props="{
                    checkStrictly: true,
                    label: 'name',
                    children: 'items',
                    value: 'id',
                  }" @change="dataChange">
                  </el-cascader>
                </el-form-item>
                <el-form-item label="管控时间：" label-width="90px">
                  <div>
                    <el-date-picker value-format="yyyy-MM-dd" v-model="formLabelAlign.control_begin" type="date"
                      placeholder="开始日期" @change="DateChange()">
                    </el-date-picker>
                    至
                    <el-date-picker value-format="yyyy-MM-dd" v-model="formLabelAlign.control_end" type="date"
                      placeholder="结束日期" :picker-options="pickerOptions">
                    </el-date-picker>
                  </div>
                </el-form-item>

                <!-- <el-form-item label="是否启用：">
              <el-select
                v-model="formLabelAlign.is_enable"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
              </el-form>
              <div class="search_button">
                <div>
                  <el-button @click="way_tableData(true)" type="primary" icon="el-icon-search">查询</el-button>
                  <el-button @click="on_reset" icon="el-icon-refresh-left">重置</el-button>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个</span>
            <span class="span2">已选择 {{ more_List.length }} 个</span>
            <i @click="way_tableData" class="span_i el-icon-refresh">数据更新</i>
          </p>
          <div>
            <el-button type="primary" @click="on_copy('/security/risk_pointredact')" icon="el-icon-plus">新建</el-button>
            <!-- <el-button @click="on_pop_flow" icon="el-icon-s-custom"
                    >审批</el-button
                  > -->
            <el-button @click="on_poprw_flow" icon="el-icon-tickets">创建任务</el-button>
            <el-button @click="on_deleteList" icon="el-icon-close">删除</el-button>
          </div>
        </div>
        <div>
          <el-table highlight-current-row @current-change="way_singleselect" @selection-change="way_moreselect"
            :data="table_List" :header-cell-style="{ background: '#f8f8f9' }" style="flex: 1">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="id" width="55" label="ID">
            </el-table-column>

            <el-table-column prop="name" label="名称">
              <template slot-scope="scope">
                <el-link @click="on_assess(scope.row)" type="primary">{{
                  scope.row.name
                }}</el-link>
              </template>
            </el-table-column>

            <el-table-column prop="department" label="责任部门"></el-table-column>
            <el-table-column prop="cate_name" label="分类"></el-table-column>

            <el-table-column prop="address" label="风险等级">
              <template slot-scope="scope">
                <div class="sign">
                  <div class="sign-1" :style="{ background: scope.row.back_color }">
                    {{ scope.row.level_type }}
                  </div>
                  <div class="sign-2" :style="{
                    border: `1px  solid ${scope.row.back_color}`,
                    color: scope.row.back_color,
                  }">
                    {{ scope.row.level_color }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="标签">
              <template slot-scope="scope">
                <el-tag v-for="item in scope.row.tags" :key="item.id" style="margin: 0 5px 10px 0">
                  {{ item }}
                </el-tag>
              </template>
            </el-table-column>

            <!-- <el-table-column prop="address" label="评估状态">
                    <template slot-scope="scope">
                      <el-link @click="on_assess(scope.row)" type="primary">{{
                        scope.row.status
                      }}</el-link>
                    </template>
                  </el-table-column> -->

            <el-table-column prop="check_times" label="检查频率"></el-table-column>

            <el-table-column prop="address" label="任务完成">
              <template slot-scope="scope">
                <div class="schedule">{{ scope.row.finish_percent }}</div>
              </template>
            </el-table-column>

            <el-table-column label="当前任务">
              <template slot-scope="scope">
                <div v-if="scope.row.instance">
                  <div class="schedule" v-for="(item, index) in scope.row.instance" :key="index">
                    <el-tooltip placement="bottom" effect="light">
                      <div slot="content">
                        开始时间:{{ item.begin_time }}<br />结束时间:{{
                          item.end_time
                        }}
                      </div>
                      <p>{{ item.task_name }}</p>
                    </el-tooltip>
                  </div>
                </div>
                <div v-else>未创建任务</div>
              </template>
            </el-table-column>

            <el-table-column prop="last_score" label="扣分"></el-table-column>

            <!-- <el-table-column label="二维码">
              <template slot-scope="scope">
                <el-image
                  style="width: 80px; height: 80px"
                  :src="scope.row.qr_code"
                  :preview-src-list="[scope.row.qr_code]"
                >
                </el-image>
              </template>
            </el-table-column> -->

            <el-table-column prop="address" label="操作">
              <template slot-scope="scope">
                <el-link v-if="scope.row.show_edit == 1" class="list_sty" type="primary" @click="
                  on_copy('/security/risk_pointredact', scope.row.id)
                  ">修改</el-link>
                <el-link class="list_sty" type="primary" @click="
                  on_particulars(
                    '/security/Soldier_particulars',
                    scope.row.id
                  )
                  ">详情</el-link>
                <el-link class="list_sty" type="primary" @click="on_card(scope.row.id)">告知卡</el-link>
                <el-link class="list_sty" type="success" @click="
                  on_copy('/security/risk_pointredact', scope.row.id, 1)
                  ">复制</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination @current-change="way_page" :page-size="page_size" layout="prev, pager, next, jumper"
            :total="total" :current-page="page">
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog title="发起审批" show-close :visible.sync="auditpop_show" center>
      <auditpop v-if="auditpop_show" ref="auditpop" :refbool.sync="auditpop_show" flowcode_type="flow_dangerous"
        @way_tableData="way_tableData" />
    </el-dialog>

    <el-dialog title="任务" show-close :visible.sync="renwupop_show" center>
      <renwupop v-if="renwupop_show" ref="renwupop" :refbool.sync="renwupop_show" task_code="dangerous_recorder"
        @way_tableData="way_tableData" />
    </el-dialog>

    <el-dialog title="评估" :visible.sync="assess_pop" show-close>
      <el-form label-width="120px" ref="formName" :model="assess_Data">
        <el-form-item label="检查方式：" prop="check_type" :rules="[{ required: true, message: '请选择检查方式' }]">
          <el-select v-model="assess_Data.check_type" placeholder="请选择检查方式">
            <el-option v-for="item in check_type" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <div style="display: flex">
          <el-form-item label="检查频率：" prop="check_times" :rules="[{ required: true, message: '请选择检查频率' }]">
            <el-select v-model="assess_Data.check_times" placeholder="请选择检查频率">
              <el-option v-for="item in check_times" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label-width="20px" v-if="assess_Data.check_times == 5" prop="times_days">
            <el-input style="width: 200px" placeholder="请输入天数" size="mini" class="form-inp"
              v-model.number="assess_Data.times_days">
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
        </div>

        <el-form-item label="评估类型：" prop="level_type" :rules="[{ required: true, message: '请选择评估类型' }]">
          <el-select v-model="assess_Data.level_type" placeholder="请选择评估类型">
            <el-option v-for="item in level_list" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评估等级：" prop="level_color" :rules="[{ required: true, message: '请选择评估等级' }]">
          <el-select v-model="assess_Data.level_color" placeholder="请选择评估等级">
            <el-option v-for="item in level_color_list" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考核分数" prop="score" :rules="[{ required: true, message: '考核分数不能为空' }]">
          <el-input type="number" placeholder="考核分数" size="mini" class="form-inp" v-model="assess_Data.score"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          assess_name
        }}</el-button>
        <el-button @click="assess_pop = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";
import auditpop from "../../components/audit_pop";
import renwupop from "../../components/renwu_pop";
import BepartmentTree from "../../components/Bepartment_tree";

import {
  armyindex,
  dangerousstatusupdate,
  dangerousestimateInfo,
  dangerousestimateSave,
  dangerousdelete,
} from "../../assets/request/api";
export default {
  name: "risk_source",
  mixins: [table_minin],
  components: { auditpop, renwupop, BepartmentTree },
  data() {
    let skyRuel = (rule, value, callback) => {
      if (!this.$tools.verify_sky(value)) {
        callback(new Error("请输入正确天数"));
      } else {
        callback();
      }
    };
    return {
      drawer_show: false,
      department_name: "",
      is_extend: true,
      formLabelAlign: {
        name: "",
        id: "",
        level_color: "",
        cate_id: "",
        department_id: "",
        check_times: "",
        status: "",
        control_begin: "",
        control_end: "",
      },
      options: [],
      catesList: [],
      check_times: [],
      status_list: [],
      //评估操作
      assessrow: "",
      assess_pop: false,
      assess_name: "评估",
      assess_Data: {
        level_type: "",
        level_color: "",
        check_times: "",
        score: 0,
        check_type: "",
        times_days: "",
      },
      level_color_list: [],
      level_list: [],
      check_type: [],
      rulesass: {
        times_days: [
          { validator: skyRuel, trigger: "blur" },
          { required: true, message: "请输入天数" },
        ],
      },
    };
  },
  created() {
    this.url = armyindex;
    this.is_enableUrl = dangerousstatusupdate;
    this.delete_Url = dangerousdelete;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "color_list";
    this.way_tableData();
  },
  methods: {
    on_card(id) {
      const { href } = this.$router.resolve({
        path: "/risk_card",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },

    //统计周期结束日期禁用
    DateChange(type) {
      if (!type) {
        this.formLabelAlign.control_end = "";
      }
      let that = this;
      this.pickerOptions = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(
            date,
            that.formLabelAlign.control_begin
          );
        },
      };
    },

    on_copy(path, id, type) {
      this.$router.push({
        path: path,
        query: {
          id: id,
          type: type,
        },
      });
    },
    way_page(val) {
      this.page = val;
      this.way_tableData();
    },
    way_extend(data) {
      this.catesList = data.catesList;
      this.check_times = data.check_times;
      this.status_list = data.status_list;
    },
    dataChange(event) {
      let events = event[event.length - 1];
      this.formLabelAlign.cate_id = events;
    },
    on_assess(row) {
      this.assessrow = row;
      dangerousestimateInfo({ id: row.id }).then((res) => {
        let { level_color_list, info, level_list, check_type } = res.data;
        this.level_color_list = level_color_list;
        this.level_list = level_list;
        this.check_type = check_type;

        this.assess_Data.level_type = info.level_type;
        this.assess_Data.level_color = info.level_color;
        this.assess_Data.score = info.score;
        this.assess_Data.check_times = info.check_times;
        this.assess_Data.check_type = info.check_type;
        this.assess_Data.times_days = info.times_days;
        this.assess_pop = true;
      });
    },
    //保存评估
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { assess_Data, assessrow } = this;
          assess_Data.id = assessrow.id;
          dangerousestimateSave(assess_Data).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              this.way_tableData();
              setTimeout(function () {
                that.assess_pop = false;
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.BM,
.DJ {
  ::v-deep .el-form-item__content {
    display: flex;
  }
}

div.justify {
  text-align: justify;
  width: 69px;
  font-size: 14px;
  color: #606266;
  height: 32px;
  margin-right: 21px;
}

div.justify>span {
  display: inline-block;
  padding-left: 100% !important;
}

.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}

.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.form-inp {
  width: 200px;
}

.paging {
  text-align: center;
  padding: 10px;
}

.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 10px;
  }

  p {
    display: flex;
    align-items: center;
  }
}

.span1 {
  font-size: 14px;
  color: #000000;
}

.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}

.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
}

.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.list_sty {
  margin-right: 10px;
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}

.sign {
  display: flex;

  div {
    width: 30px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 9px;
    color: #ffffff;
  }
}

.sign-1 {
  border-radius: 5px 0 0 5px;
}

.sign-2 {
  border-radius: 0 5px 5px 0;
}
</style>
